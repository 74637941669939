import React, { useState } from "react";
import Slider from "react-slick";
import "./News.scss";
import news1 from "../../assets/images/news/image.webp";
import news2 from "../../assets/images/news/image1.webp";
import news3 from "../../assets/images/news/image2.webp";
import news4 from "../../assets/images/news/image3.webp";
import WebPlaceholder from "../../assets/images/news/WebArticles.JPG";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const News = () => {
  const webArticles = [
    {
      date: "12/02/2024",
      source: "Nithi & Muthaleedu",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `வணிக நிறுவனங்கள், தனிநபர்கள் தங்களுக்கு ஏற்ற காப்பீட்டு திட்டங்களை தேர்வு செய்ய கவர்டன் இன்சூரன்ஸ்..!`,
      link: "https://www.nithimuthaleedu.co.in/2024/11/blog-post_17.html?m=1",
      image: WebPlaceholder,
    },
    {
      date: "12/02/2024",
      source: "Myreality",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `A one-stop solution for businesses and individuals seeking expert risk management Investment`,
      link: "https://www.myreality.co.in/2024/11/coverton-insurance-broking-one-stop.html?m=1",
      image: WebPlaceholder,
    },
    {
      date: "12/02/2024",
      source: "Chennai Prime News",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `A Comprehensive Insurance Broking Services to Simplify and Enhance Risk Management for Businesses and Individuals`,
      link: "https://chennaiprimenews.com/coverton-insurance-broking-launches-comprehensive-insurance-broking-services-to-simplify-and-enhance-risk-management-for-businesses-and-individualschennai/",
      image: WebPlaceholder,
    },
    {
      date: "12/02/2024",
      source: "Life Insurance International",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `Coverton Insurance Broking launches services to simplify risk management`,
      link: "https://www.lifeinsuranceinternational.com/news/coverton-insurance-broking-launches-services-to-simplify-risk-management/",
      image: WebPlaceholder,
    },

    {
      date: "12/02/2024",
      source: "Yahoo! Finance",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `Coverton Insurance Broking launches services to simplify risk management`,
      link: "https://finance.yahoo.com/news/coverton-insurance-broking-launches-services-155549314.html",
      image: WebPlaceholder,
    },
    {
      date: "12/02/2024",
      source: "MEDIABRIEF",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `Coverton Insurance Broking launches comprehensive services to enhance risk management`,
      link: "https://mediabrief.com/coverton-insurance-broking-launches-comprehensive-services-to-enhance-risk-management/",
      image: WebPlaceholder,
    },
    {
      date: "12/02/2024",
      source: "Express News",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      name: `Comprehensive Insurance Broking Services to Simplify and Enhance Risk Management for Businesses and Individuals`,
      link: "https://expressnews.asia/coverton-insurance-broking-launches-comprehensive-insurance-broking-services-to-simplify-and-enhance-risk-management-for-businesses-and-individuals/",
      image: WebPlaceholder,
    },
  ];

  const newspaperArticles = [
    {
      name: "Coverton launches comprehensive insurance borking",
      image: news1,
    },
    {
      name: "Coverton launches insurance broking biz",
      image: news2,
    },
    {
      name: "Coverton Insurance launches insurance broking services",
      image: news3,
    },
    {
      name: "IBAI",
      image: news4,
    },
  ];
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="news-section">
        <h2 className="section-title">Newspaper Articles</h2>
        <Slider {...carouselSettings}>
          {newspaperArticles.map((article, index) => (
            <div className="news-card with-image" key={index}>
              <img
                src={article.image}
                alt={article.name}
                className="news-image"
                onClick={() => handleImageClick(article.image)}
              />
              <h3 className="news-title">{article.name}</h3>
            </div>
          ))}
        </Slider>
      </div>

      <div className="news-section">
        <h2 className="section-title">Web Articles</h2>
        <img
          src={WebPlaceholder}
          className="web-placeholder"
          alt="coverton-news"
        />
        <div className="web-articles">
          {webArticles.map((article, index) => (
            <div className="web-article-card" key={index}>
              <div className="web-content">
                <h4 className="web-article-title">
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="web-article-link"
                  >
                    {article.name}
                  </a>
                </h4>
                {/* <p className="web-article-description">{article.description}</p> */}
                <div className="web-article-meta">
                  <span>{article.date} </span> |{" "}
                  <span style={{ textAlign: "right" }}>
                    Source: {article.source}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full view" className="modal-image" />
          </div>
        </div>
      )}
    </>
  );
};

export default News;
