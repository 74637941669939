import React from "react";
import { useLocation } from "react-router-dom";
import CustomLink from "../../../Components/UI/Link";
import { headerLinks } from "./staticData";

function HeaderNavLink() {
  const { pathname } = useLocation();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const displayNavLink = headerLinks.map((link, ind) => {
    const isActive = pathname === link.to;

    return (
      <li key={ind}>
        {link.to ? (
          <CustomLink
            to={link.to}
            className={`nav-link ${isActive ? "active" : ""}`}
          >
            {link.name}
          </CustomLink>
        ) : (
          <CustomLink onClick={scrollToBottom} className="nav-link">
            {link.name}
          </CustomLink>
        )}
      </li>
    );
  });

  return <ul className="nav-link__header">{displayNavLink}</ul>;
}

export default HeaderNavLink;
